/* eslint-disable no-undef */
import axios from "axios"
import { API_URL, EMBODIMENT_PORT } from "../constants.js"

export const getPluginManifest = async (domain, headers) => {
  // remove forward slash from the end of the domain
  // domain = domain ? domain.replace(/\/$/, "") : null;

  // Normalize the domain to always include https protocol if not present
  const normalizedDomain =
    domain && !domain.startsWith("http://") && !domain.startsWith("https://")
      ? `https://${domain}`
      : domain
  try {
    const { data } = await axios.get(
      `${normalizedDomain || API_URL}/chatbot/chat-configs`,
      {
        headers,
      }
    )
    return data
  } catch {
    return domain
  }
}

const getPort = (port) => {
  const host = window?.location?.host
  const defaultPort = EMBODIMENT_PORT
  if (host.includes("localhost")) { // when running this app directly for development
    return port || defaultPort
  }

  return port || window?.location?.port || defaultPort
}

export const getNormalizedDomain = (domain, port) =>
  domain && !domain.startsWith("http://") && !domain.startsWith("https://")
    ? domain.includes("localhost") || domain.includes("localagent")
      ? `http://${domain}:${getPort(port)}`
      : `https://${domain}`
    : domain
