import { API_URL } from "../constants.js";
import { getNormalizedDomain } from "./api.js";

export const fetchSettings = async (domain) => {
  try {
    const response = await fetch(
      `${getNormalizedDomain(domain) || API_URL}/chatbot/params`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    return response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const verifyApiKey = async (domain, apiKey) => {
  try {
    const response = await fetch(
      `${getNormalizedDomain(domain) || API_URL}/chatbot/verify-chat-key`,
      {
        method: "POST",
        body: JSON.stringify({
          apiKey,
        }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.json();
  } catch (error) {
    throw new Error(error);
  }
};
